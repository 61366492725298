
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useContext } from "react";
import { Flex, Box } from "rebass/styled-components";
import useTranslation from "next-translate/useTranslation";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Layout from "../components/Layout";
import LoginForm from "../components/login/LoginForm";
import { withoutAuth } from "../lib/auth";
import { LocaleContext } from "../components/locale";
interface Props {
    query: Record<string, any>;
}
function Login({ query }: Props) {
    const { t } = useTranslation("login");
    const { locale } = useContext(LocaleContext);
    return (<GoogleReCaptchaProvider language={locale} reCaptchaKey={process.env.googleReCapthaV3ApiKey} scriptProps={{ defer: true }}>
      <Layout withTransparentHeader immersive title={t("login:title")} withHrefLang>
        <Flex alignItems="center" height={["auto", "100vh"]} variant="dogHair">
          <Box variant="layout.small">
            <LoginForm query={query}/>
          </Box>
        </Flex>
      </Layout>
    </GoogleReCaptchaProvider>);
}
const getServerSideProps = withoutAuth()(async ({ query }) => {
    return { props: { query } };
});
export default Login;

    async function __Next_Translate__getServerSideProps__19565db8080__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19565db8080__ as getServerSideProps }
  