import React, { useCallback, useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Box, Text, Flex } from "rebass/styled-components";
import { Label } from "@rebass/forms/styled-components";
import Button from "../common/Button";
import Card from "../common/Card";
import Input from "../common/Input";
import Heading from "../common/Heading";
import PasswordInput from "../signup/PasswordInput";
import { Formik } from "formik";
import FacebookButton from "../signup/FacebookButton";
import AppleButton, { AppleAuth } from "../signup/AppleButton";
import Separator from "../common/Separator";
import Link from "../common/Link";
import ForgotPassword from "./ForgotPassword";
import { FullLoader } from "../common/loader";
import gql from "graphql-tag";
import { useMutation, useApolloClient } from "@apollo/client";
import { login as authLogin } from "../../lib/auth";
import { useAuthDispatch } from "../../components/common/AuthContext";
import { CurrentUserFragment } from "../../fragments/index.fragments";
import { trackEvent } from "../../lib/event-tracking";
import { ApolloError } from "apollo-boost";
import { CurrentUser } from "../../interfaces";
import { ThirdPartyAuth } from "../../enums";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { validateEmail } from "../../lib/utils";

interface Props {
  query: Record<string, any>;
}

interface State {
  email?: string;
  password?: string;
  thirdPartyToken?: string;
  thirdPartyOption?: ThirdPartyAuth.APPLE | ThirdPartyAuth.FB;
  name?: string;
  surname?: string;
}

interface ErrorObject {
  email: any;
  password: any;
  facebookToken: any;
}

const LOGIN_USER = gql`
  mutation login(
    $email: String!
    $password: String!
    $recaptchaToken: String!
  ) {
    login(
      input: {
        email: $email
        password: $password
        recaptchaToken: $recaptchaToken
      }
    ) {
      token
      user {
        ...CurrentUserParts
      }
      errors {
        message
        path
      }
    }
  }
  ${CurrentUserFragment}
`;

const FACEBOOK_LOGIN = gql`
  mutation authenticateWithFacebook($email: String!, $facebookToken: String!) {
    authenticateWithFacebook(
      input: { email: $email, facebookToken: $facebookToken }
    ) {
      token
      user {
        ...CurrentUserParts
      }
      errors {
        message
        path
      }
    }
  }
  ${CurrentUserFragment}
`;

const APPLE_LOGIN = gql`
  mutation authenticateWithApple($input: AuthenticateWithAppleInput!) {
    authenticateWithApple(input: $input) {
      token
      user {
        ...CurrentUserParts
      }
      errors {
        message
        path
      }
    }
  }
  ${CurrentUserFragment}
`;

const LoginForm = (props: Props) => {
  const [state] = useState<State>({
    email: "",
    password: "",
    thirdPartyToken: ""
  });
  const { t } = useTranslation("login");
  const { query } = props;
  const apolloClient = useApolloClient();
  const authDispatch = useAuthDispatch();
  const [loginErrors, setLoginError] = useState(false);
  const [thirdPartyErrors, setThirdPartyErrors] = useState<boolean | string>(
    false
  );

  const [recaptchaToken, setRecaptchaToken] = useState<string>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const fetchRecaptchaToken = useCallback(async () => {
    if (executeRecaptcha) {
      setRecaptchaToken(await executeRecaptcha("login"));
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    fetchRecaptchaToken();
  }, [fetchRecaptchaToken]);

  const successfulLogin = (token: string, user: CurrentUser) => {
    authDispatch({
      type: "setAccountMode",
      payload: { accountMode: user.type }
    });
    authLogin({
      token,
      user,
      apolloClient,
      pathToRedirect: query.redirect || "/"
    });

    //clearing previous errors if any
    setThirdPartyErrors(false);
    setLoginError(false);

    //tracking sign in
    trackEvent("user_signed_in", { type: user.type });
  };

  const [login, { loading: regularLoading }] = useMutation(LOGIN_USER, {
    onCompleted: data => {
      const { user, token, errors } = data.login;
      if (!errors.length) {
        successfulLogin(token, user);
      } else {
        setLoginError(true);
        fetchRecaptchaToken();
        trackEvent("user_sign_in_failed");
      }
    },
    onError: e => {
      fetchRecaptchaToken();
      setThirdPartyErrors(false);
      setLoginError(true);
    }
  });

  const [fbLogin, { loading: fbLoading }] = useMutation(FACEBOOK_LOGIN, {
    onCompleted: data => {
      const { user, token, errors } = data.authenticateWithFacebook;
      if (!errors.length) {
        successfulLogin(token, user);
      } else {
        setLoginError(false);
        setThirdPartyErrors(data.authenticateWithFacebook.errors[0].message);
      }
    },
    onError: (error: ApolloError) => {
      setLoginError(false);
      setThirdPartyErrors(error.message);
    }
  });

  const [appleLogin, { loading: appleLoading }] = useMutation(APPLE_LOGIN, {
    onCompleted: data => {
      const { user, token, errors } = data.authenticateWithApple;

      if (!data.authenticateWithApple.errors.length) {
        successfulLogin(token, user);
      } else {
        setLoginError(false);
        setThirdPartyErrors(data.authenticateWithFacebook.errors[0].message);
      }
    },
    onError: (error: ApolloError) => {
      setLoginError(false);
      setThirdPartyErrors(error.message);
    }
  });

  const loading = regularLoading || fbLoading || appleLoading;

  const validate = (values, errors, t) => {
    if (values?.thirdPartyOption != ThirdPartyAuth.APPLE) {
      if (!values.email) {
        errors.email = !values?.thirdPartyOption
          ? t("login:required")
          : t("login:facebookWithoutEmail");
      } else if (!validateEmail(values.email.trim())) {
        errors.email = t("login:wrongFormat");
      }
    }

    if (!values?.thirdPartyOption) {
      if (!values.password) {
        errors.password = t("login:required");
      } else if (values.password.length < 6) {
        errors.password = t("login:short");
      }
    }
  };

  const submit = (values: State, { setSubmitting }) => {
    if (loading) return;
    switch (values?.thirdPartyOption) {
      case ThirdPartyAuth.APPLE:
        appleLogin({
          variables: {
            input: {
              idToken: values.thirdPartyToken,
              name: values?.name,
              surname: values.surname
            }
          }
        });
        break;

      case ThirdPartyAuth.FB:
        fbLogin({
          variables: {
            email: values.email,
            facebookToken: values.thirdPartyToken
          }
        });
        break;

      default:
        login({
          variables: {
            email: values.email,
            password: values.password,
            recaptchaToken
          }
        });
    }
    setSubmitting(false);
  };

  return (
    <Card
      variant="box"
      width={1}
      mx="auto"
      px={[0, 0, 2, 4]}
      py={[2, 4]}
      className={"mobile-borderless"}
    >
      <GoogleReCaptcha onVerify={setRecaptchaToken} action="login" />
      <Formik
        initialValues={state}
        validate={values => {
          const errors = {} as ErrorObject;
          validate(values, errors, t);
          return errors;
        }}
        onSubmit={submit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          submitForm,
          isSubmitting
        }) => (
          <Box as="form" onSubmit={handleSubmit}>
            {(isSubmitting || loading) && <FullLoader />}
            <Flex flexWrap="wrap">
              <Box mb={2}>
                <Heading as="h1" mb={2}>
                  {t("title")}
                </Heading>
              </Box>
              {/* <Box mb={2} width={1}>
                <FacebookButton
                  text={t("login:loginWithFacebook")}
                  callback={response => {
                    setFieldValue("thirdPartyOption", ThirdPartyAuth.FB);
                    setFieldValue("thirdPartyToken", response.accessToken);
                    if (response.email) setFieldValue("email", response.email);
                    submitForm();
                  }}
                />
              </Box> */}
              <Box mb={2} width={1}>
                <AppleButton
                  text={t("login:loginWithApple")}
                  callback={(response: AppleAuth) => {
                    const { authorization, user } = response;
                    setFieldValue("thirdPartyOption", ThirdPartyAuth.APPLE);
                    setFieldValue("thirdPartyToken", authorization.id_token);
                    if (response?.user) {
                      setFieldValue("name", user.name.firstName);
                      setFieldValue("surname", user.name.lastName);
                    }

                    submitForm();
                  }}
                />
              </Box>
              <Separator />
              <Box width={1} variant={errors.email && touched.email && "error"}>
                <Label flexWrap="wrap">
                  <Text width={1} mb={1}>
                    {t("email")}
                  </Text>
                  <Box width={1}>
                    <Input
                      name="email"
                      type="email"
                      value={values.email}
                      iconRight="mail"
                      placeholder={t("email")}
                      onChange={handleChange}
                      data-test="email"
                    />
                  </Box>
                  {errors.email && touched.email && (
                    <Text
                      as="span"
                      data-test="validation"
                    >{`${errors.email}`}</Text>
                  )}
                </Label>
              </Box>
              <Box
                width={1}
                mt={2}
                variant={errors.password && touched.password && "error"}
              >
                <Label flexWrap="wrap">
                  <Text width={1} mb={1}>
                    {t("password")}
                  </Text>
                  <Box width={1}>
                    <PasswordInput
                      text={t("password")}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      disabled={!!values.thirdPartyToken}
                      data-test="password"
                    />
                  </Box>
                </Label>
                {errors.password && touched.password && (
                  <Text
                    as="span"
                    data-test="validation"
                  >{`${errors.password}`}</Text>
                )}
              </Box>
              <Box mt={2} width={1}>
                <Button type="submit" size="large" width={1} data-test="submit">
                  {t("submit")}
                </Button>
              </Box>
            </Flex>
          </Box>
        )}
      </Formik>
      {loginErrors && (
        <Box py={2}>
          <Text
            as="p"
            color="errorRed"
            textAlign="center"
            sx={{
              fontSize: 3
            }}
          >
            {t("loginFailed")}
          </Text>
        </Box>
      )}
      {!!thirdPartyErrors && (
        <Box py={2}>
          <Text
            as="p"
            color="errorRed"
            textAlign="center"
            sx={{
              fontSize: 3
            }}
          >
            {thirdPartyErrors}
          </Text>
        </Box>
      )}
      <Box py={2}>
        <ForgotPassword />
      </Box>
      <Box
        height="1px"
        sx={{
          borderBottom: "1px solid",
          borderColor: "gray"
        }}
      ></Box>
      <Box py={2}>
        <Link href={{ pathname: "/signup", query: { ...query } }}>
          <Text
            as="p"
            color="springGreen"
            textAlign="center"
            sx={{
              cursor: "pointer",
              fontSize: 3
            }}
          >
            {t("signup")}
          </Text>
        </Link>
      </Box>
    </Card>
  );
};

export default LoginForm;
